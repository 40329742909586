import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Overview",
  "route": "/overview"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Overview`}</h1>
    <p>{`Smartlike is a decentralized donation network. Transactions are stored on a public ledger hosted by user devices, money is directly transferred from user to a donation recipient without the middle men. Decentralization ensures privacy, security and freedom from censorship. Nobody owns the platform or sells a service.`}</p>
    <p><strong parentName="p">{`How it works:`}</strong>{` Smartlike users are both donors and creators at the same time. Even if your don't create content but occasionally post comments and others think they are worth promoting with smartlikes, you receive micro-donations as a comment author.`}</p>
    <p>{`Donors fill their Smartlike accounts with funds and spread them among their favorites with one-click donations, smartlikes, lift paywalls or create subscriptions a la Patreon while keeping their privacy.`}</p>
    <p>{`Creators accumulate these streaming micro-donations and turn them into money by helping donors fill their accounts:`}</p>
    <ul>
      <li parentName="ul">{`A donor makes an ordinary direct donation to their favorite creator using the billing info published on Smartlike.`}</li>
      <li parentName="ul">{`The creator confirms the donation receipt, manually or automatically.`}</li>
      <li parentName="ul">{`The network then transfers the exact same amount of accumulated smartlikes from the creator to the donor.`}</li>
    </ul>
    <p>{`Smartlike doesn't create money or financial derivatives nor does it provide custodial services. It effectively enables free micro-transfer accounting, while protecting your privacy. The sensitive payment process is decentralized and based on trusted relationships between creators and their audience, while transaction accounting is protected by trust-less blockchain technology. Millions of transfers per second will be possible.`}</p>
    <p>{`Note: Smartlike beta is published with a few limitations regarding centralization of processing and governance. When it comes out of beta, it will be set completely free with data processed on user devices and system parameters controlled by the community.`}</p>
    <p><strong parentName="p">{`Jump-start for creators:`}</strong>{` Get yourself a Smartlike account (no email or registration required) and sign your content or connect your social media accounts - microdonations will be automatically routed to your Smartlike account. Publish your billing connection to turn smartlikes into money on your bank account. `}<a parentName="p" {...{
        "href": "/docs/how-to-setup-content-monetization"
      }}>{`Step-by-step instructions`}</a>{`.`}</p>
    <p><strong parentName="p">{`For platform owners:`}</strong>{` You can `}<a parentName="p" {...{
        "href": "/docs/how-to-setup-content-monetization"
      }}>{`set a share`}</a>{` to receive from smartlikes collected by the content hosted on your domain.`}</p>
    <p><strong parentName="p">{`Donation fees:`}</strong>{` There is no one to collect fees in the system and there're no fees on donation transfers for typical usage. Only in case Smartlike should come under attack during beta, a 1% cut will be taken and distributed among all creators to protect the system: imagine an anonymous abuser sending a few cents between their pockets a million times per second for free - that would unnecessarily strain platform's resources.`}</p>
    <p><strong parentName="p">{`Fake likes:`}</strong>{` Smartlike is a optional donation type which is used to promote creator's content, make it visible in charts and thus create recommendation value for users. The challenge here is to guard against fake likes while respecting privacy. (Imagine fraudulent creators sending smartlikes to themselves to promote their content in the charts). To mitigate the issue, the network takes a cut (around 5%) of the like amount and `}<a parentName="p" {...{
        "href": "/docs/fake-likes"
      }}>{`evenly distributes it among creators`}</a>{`. Abusers will have to pay to those they play against, while superstars will share part of their likes with less successful creators for the sake of system integrity. Crowd sourced tagging and moderation provide further `}<a parentName="p" {...{
        "href": "/docs/decentralized-crowdsourced-moderation"
      }}>{`defenses`}</a>{` against fake likes.`}</p>
    <p><strong parentName="p">{`Currency exchange:`}</strong>{` Smartlike accounts can be nominated in the `}<a parentName="p" {...{
        "href": "/rates"
      }}>{`following currencies`}</a>{`. They are automatically converted on transfer according to the current exchange rate without any commission with double float precision. Exchange rates are determined by the community consensus.`}</p>
    <p><strong parentName="p">{`Transparency:`}</strong>{` All Smartlike data is `}<a parentName="p" {...{
        "href": "/audit"
      }}>{`public`}</a>{` and open to audit and unrestricted use. Transactions are transparent and cryptographically signed by users. Sensitive parts like device synchronization and direct messages are end-to-end encrypted by users.`}</p>
    <p><strong parentName="p">{`Integration:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://docs.smartlike.org/embed.html"
        }}>{`Embed`}</a>{` donate/login/smartlike/subscribe buttons to your page`}</li>
      <li parentName="ul">{`For lower level integration of micro-donations without commission into your project, a free and unrestricted API is coming soon`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      